<template>
	<div class="row">
		<div class="col-12">
			<div class="card">
				<div class="card-body">
					<h4 class="card-title">
						Projects Table
					</h4>
					<div 
						v-if="showUpgradeAlert"
						class="upgrade-alert-container mb-4 text-center"
					>
						<b-alert
							show
							class="upgrade-alert" role="alert"
							variant="warning"
						>
							<strong>Heads up!</strong>
							Free accounts are limited to one active project. 
							<a @click="$router.push({ name: 'ClientPricing' })" class="link">Upgrade</a> for unlimited access.
						</b-alert>
					</div>
					<div class="row mb-md-2" />
					<div v-if="tableData && tableData.length">
						<div
							class="table-responsive mb-0"
						>
							<b-table
								:items="tableData"
								:fields="fields"
								responsive="sm"
								:per-page="perPage"
								:sort-by.sync="sortBy"
								:sort-desc.sync="sortDesc"
								:no-local-sorting="true"
								@sort-changed="sortingChanged"
								:table-class="'table-centered'"
							>
								<template #cell(project_name)="data">
									{{ data.item.project_name }}
									<small
										v-if="data.item.archived_at"
										class="text-muted"
									>
										(archived)
									</small>
								</template>
								<template #cell(action)="data">
									<button
										v-if="!data.item.archived_at"
										class="btn btn-primary table-button"
										@click="$emit('archiveProject', $event, data.item.id)"
									>
										<i class="fas fa-archive" />
										Archive
									</button>
									<button
										v-else
										class="btn btn-primary table-button"
										@click="$emit('unarchiveProject', $event, data.item.id)"
										:disabled="isUnarchiveProjectButtonDisabled"
									>
										<i class="fas fa-archive" />
										Unarchive
									</button>
									<button
										class="btn btn-primary table-button"
										:disabled="data.item.archived_at"
										@click="$emit('editProject', $event, data.item.id)"
									>
										<i class="fas fa-pencil-alt" />
										Edit
									</button>
									<button
										class="btn btn-danger table-button"
										:disabled="data.item.archived_at"
										@click="$emit('deleteProject', $event, data.item.id)"
									>
										<i class="far fa-trash-alt" />
										Delete
									</button>
								</template>
								<template #head(action)="field">
									<span></span>
								</template>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
								<div class="dataTables_paginate paging_simple_numbers float-right">
									<ul class="pagination pagination-rounded mb-0">
										<b-pagination
											v-model="currentPage"
											:total-rows="totalItemsCount"
											:per-page="perPage"
											@input="getPaginatedTableData(currentPage)"
										/>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div v-else-if="tableData">
						<h2 class="text-center empty-table">Nothing here yet!</h2>
						<p class="text-center text-muted">Try creating a project and some time entries to get started.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import appConfig from "@/app.config";

export default {
	name: 'ProjectsTable',
	page: {
		title: "Projects Table",
		meta: [{ name: "ClientReportsTable", content: appConfig.description }]
	},
	data() {
		return {
			title: "Projects Table",
			currentPage: 1,
			perPage: 15,
			sortBy: "project_name",
			sortDesc: false,
			fields: [
				{ key: "project_name", sortable: true },
				'action',
			],
			showUpgradeAlert: null,
		};
	},
	computed: {
		tableData() {
			return this.$store.state.projectsTable.tableData;
		},
		totalItemsCount() {
			return this.$store.state.projectsTable.totalItemsCount;
		},
		userInfo() {
			return this.$store.state.user.userInfo;
		},
		isUnarchiveProjectButtonDisabled() {
			if (
				this.userInfo.subscription
				&& !this.userInfo.subscription.isActive
				&& !this.userInfo.subscription.isOnTrial
				&& this.hasUnarchivedProject
			) {
				return true;
			}
		},
		hasUnarchivedProject() {
			const activeProjectsCount = this.tableData
				.filter(project => project.archived_at === null)
				.length;
			return activeProjectsCount > 0;
		},
	},
	watch: {
		userInfo(userInfo) {
			if (userInfo.subscription && !userInfo.subscription.isActive && !userInfo.subscription.isOnTrial) {
				this.showUpgradeAlert = true;
			} else {
				this.showUpgradeAlert = false;
			}
		},
	},
	methods: {
		getPaginatedTableData(currentPage) {
			this.$emit('updatePage', currentPage);
		},
		sortingChanged(ctx) {
			this.$emit('sortChanged', this.currentPage, ctx.sortBy, ctx.sortDesc ? 1 : 0);
		},
		dateFormatter(date) {
			return this.$moment(date).format('YYYY-MM-DD');
		},
	}
};
</script>

<style lang="scss" scoped>
.table-button {
	margin-right: 5px;
}

i {
	margin-right: 5px;
}

.tooltip {
	cursor: default;
}

.empty-table {
	margin: 50px 0;
	color: #9ca8b3;
}

.upgrade-alert {
	margin: auto;
	max-width: 600px;

	.link {
		cursor: pointer;
	}
}
</style>