<template>
	<Layout>
		<PageHeader
			:title="title"
			v-bind="actionButton"
			@openCreateProjectModal="openCreateProjectModal()"
		/>
		<ProjectsTable
			@editProject="openEditProjectModal"
			@deleteProject="openDeleteProjectModal"
			@archiveProject="archiveProject"
			@unarchiveProject="unarchiveProject"
			@updatePage="updatePage"
			@sortChanged="updateSort"
		/>
	</Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { apiPost, apiDelete, apiPatch } from "@/utils/api";
import PageHeader from "@/components/page-header";
import ProjectsTable from "@/components/client-dashboard/projects-table";
import Swal from "sweetalert2";
import appConfig from "@/app.config";
import errorPopup from '@/utils/error-popup';
import ProjectsService from '@/services/projects';

export default {
	page: {
		title: "Projects",
		meta: [{ name: "ClientProjects", content: appConfig.description }]
	},
	components: { Layout, PageHeader, ProjectsTable },
	data() {
		return {
			title: "Projects",
			actionButton: {
				buttonText: 'New Project',
				buttonEvent: 'openCreateProjectModal',
				buttonClasses: 'btn-success',
				iconClasses: 'fas fa-folder-plus',
				isDisabled: false,
				tooltip: null,
			},
			subscription: null,
		};
	},
	computed: {
		userInfo() {
			return this.$store.state.user.userInfo;
		},
		tableData() {
			return this.$store.state.projectsTable.tableData;
		},
		currentPage() {
			return this.$store.state.projectsTable.currentPage;
		},
		sort() {
			return this.$store.state.projectsTable.sort;
		},
		sortDesc() {
			return this.$store.state.projectsTable.sortDesc;
		},		
	},
	watch: {
		userInfo(userInfo) {
			this.$store.dispatch('projectsTable/update', { userId: userInfo.id, page: 1, sort: 'project_name', sortDesc: 0 });

			if (userInfo.subscription && !userInfo.subscription.isActive && !userInfo.subscription.isOnTrial) {
				this.actionButton.tooltip = 'Free accounts are limited to one active project. Upgrade for unlimited access.';
			}
		},
	},
	mounted() {
		this.$store.dispatch('user/refreshUserInfo');
	},
	methods: {
		updatePage(page) {
			this.$store.dispatch('projectsTable/update', { userId: this.userInfo.id, page, sort: this.sort, sortDesc: this.sortDesc })
		},
		updateSort(page, sort, sortDesc) {
			this.$store.dispatch('projectsTable/update', {
				userId: this.userInfo.id,
				page,
				sort,
				sortDesc,
				})
		},		
		openCreateProjectModal() {
			Swal.fire({
				title: "New Project",
				text: "Enter new project name",
				input: "text",
				showCancelButton: true,
				confirmButtonText: "OK",
				showLoaderOnConfirm: true,
				confirmButtonColor: "#556ee6",
				cancelButtonColor: "#f46a6a",
				inputValidator: (input) => {
					if (!input) {
						return 'This is a required field.'
					}
				},
				preConfirm: projectName => {
					this.createProject(projectName);
				},
				allowOutsideClick: true
			});
		},
		createProject(projectName) {
			apiPost(`users/${this.userInfo.id}/projects`, {
				project_name: projectName,
			})
				.then((result) => {
					Swal.fire({
						icon: "success",
						title: "Success!",
						html: `"${result.data.project_name}" was created.`,
						timer: 1000,
						showConfirmButton: false,
					});
					this.updatePage(this.currentPage);
				})
				.catch(error => errorPopup(error));
		},
		openEditProjectModal(event, projectId) {
			Swal.fire({
				title: "Update Project",
				text: "Enter a new name for the project",
				input: "text",
				showCancelButton: true,
				confirmButtonText: "OK",
				showLoaderOnConfirm: true,
				confirmButtonColor: "#556ee6",
				cancelButtonColor: "#f46a6a",
				inputValidator: (input) => {
					if (!input) {
						return 'This is a required field.'
					}
				},
				preConfirm: projectName => {
					this.updateProject(projectId, projectName);
				},
				allowOutsideClick: true
			});
		},
		updateProject(projectId, projectName) {
			apiPatch(`projects/${projectId}`, {
				project_name: projectName,
			})
				.then(() => {
					Swal.fire({
						icon: "success",
						title: "Success!",
						html: `Project updated.`,
						timer: 1000,
						showConfirmButton: false,
					});
					this.updatePage(this.currentPage);
				})
				.catch(error => errorPopup(error));
		},
		openDeleteProjectModal(event, projectId) {
			Swal.fire({
				title: "Delete Project",
				text: "Are you sure you want to delete this project? All time entries associated with the project will also be removed!",
				showCancelButton: true,
				confirmButtonText: "OK",
				showLoaderOnConfirm: true,
				confirmButtonColor: "#556ee6",
				cancelButtonColor: "#f46a6a",
				preConfirm: () => {
					this.deleteProject(projectId);
				},
				allowOutsideClick: true
			});
		},
		deleteProject(projectId) {
			apiDelete(`projects/${projectId}`)
				.then(() => {
					Swal.fire({
						icon: "success",
						title: "Success!",
						html: `Project deleted.`,
						timer: 1000,
						showConfirmButton: false,
					});
					this.updatePage(this.currentPage);
				})
				.catch(error => errorPopup(error));
		},
		async archiveProject(event, projectId) {
			await ProjectsService.archiveProject(projectId);
			this.updatePage(this.currentPage);
		},
		async unarchiveProject(event, projectId) {
			await ProjectsService.unarchiveProject(projectId);
			this.updatePage(this.currentPage);
		},
	},
};
</script>
